<template>
  <div id="product-carousel">
    <div v-if="blocks.length > 3">
      <div v-for="block in blocks" :key="block.id">
        <card :options="options" :block="block" />
      </div>
    </div>
    <div v-else>
      <div v-for="block in blocks" :key="block.id">
        <card :options="option" :block="block" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    card: () => import("./cardsSlider"),
  },
  data: () => ({
    options: {
      rewind: true,
      trimSpace: true,
      waitForTransition: false,
      swipeDistanceThreshold: 200,
      width: "100%",
      perPage: 4,
      arrows: false,
      drag: true,
      pagination: false,
      type: "loop",
      gap: "0.5rem",
      perMove: 1,
      start: 0,
      focus: "center",
      direction: "rtl",
      breakpoints: {
        320: {
          width: "100%",
          gap: "0.5rem",
          perPage: 1.3,
        },
        350: {
          width: "100%",
          gap: "0.5rem",
          perPage: 1.2,
        },
        375: {
          width: "100%",
          gap: "0.5rem",
          perPage: 1.5,
        },
        380: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2,
        },
        420: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2,
        },
        500: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2.2,
        },
        560: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2,
        },
        669: {
          width: "100%",
          gap: "0.5rem",
          perPage: 3,
        },
        700: {
          width: "100%",
          perPage: 3,
          gap: "0.5rem",
        },
        770: {
          width: 750,
          perPage: 3,
          gap: "0.5rem",
        },
      },
    },
    option: {
      rewind: true,
      trimSpace: true,
      waitForTransition: false,
      swipeDistanceThreshold: 200,
      width: "100%",
      perPage: 4,
      arrows: false,
      drag: true,
      pagination: false,
      gap: "0.5rem",
      perMove: 1,
      start: 0,
      focus: "center",
      direction: "rtl",
      breakpoints: {
        320: {
          width: "100%",
          gap: "0.5rem",
          perPage: 1.3,
        },
        350: {
          width: "100%",
          gap: "0.5rem",
          perPage: 1.2,
        },
        375: {
          width: "100%",
          gap: "0.5rem",
          perPage: 1.5,
        },
        380: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2,
        },
        420: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2,
        },
        500: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2.2,
        },
        560: {
          width: "100%",
          gap: "0.5rem",
          perPage: 2,
        },
        669: {
          width: "100%",
          gap: "0.5rem",
          perPage: 3,
        },
        700: {
          width: "100%",
          perPage: 3,
          gap: "0.5rem",
        },
        770: {
          width: 750,
          perPage: 3,
          gap: "0.5rem",
        },
      },
    },
  }),
  computed: {
    ...mapState({
      blocks: (state) => state.home.blocks,
    }),
  },
};
</script>
